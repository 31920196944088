import _ from "lodash";
import apiClient from "@/services/axios";
const classRoomColumns = [
  { title: "Classe", dataIndex: "name", key: "name" },
  { title: "Nombre des notes affectées", dataIndex: "count", key: "count" },
];
const StudentColumns = [
  {
    dataIndex: "StudentName",
    key: "StudentName",
    title: "élève",
  },
  {
    title: "Matières",
    key: "subjects",
    dataIndex: "subjects",
    scopedSlots: { customRender: "subjects" },
  },
];
export default {
  name: "studentSubject",
  props: {
    data: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    display() {
      return _.values(_.groupBy(this.data, (item) => item.classroom._id)).map(
        (classroom) => ({
          _id: classroom[0].classroom._id,
          key: classroom[0].classroom._id,
          name: classroom[0].classroom.name,
          count: classroom.length,
          data: _.values(_.groupBy(classroom, (item) => item.student._id)).map(
            (studentData) => ({
              StudentName:
                studentData[0].student.firstName +
                " " +
                studentData[0].student.lastName,
              _id: studentData[0].student._id,
              subjects: studentData.map((subjectData) => ({
                subjectName: subjectData.subject.name,
                subsubjectName: subjectData.subsubject
                  ? subjectData.subsubject.name
                  : null,
                _id: subjectData.subject._id,
                mark: subjectData.mark,
                update: subjectData.updatedAt,
              })),
            })
          ),
        })
      );
    },
    defaultExpan() {
      return (this.defaultExpan = this.display[0]
        ? [this.display[0]._id]
        : [""]);
    },
  },
  data() {
    return {
      classRoomColumns,
      StudentColumns,
      loading: false,
    };
  },
  methods: {
    handleCancel: function () {
      this.$emit("cancel");
    },
    handleOk: function () {
      this.loading = true;
      const markToDelete = this.data.map((m) => m._id);
      console.info(markToDelete);
      apiClient
        .post("/bulletin/v2/mark/destroy", {
          _id: markToDelete,
        })
        .then((res) => {
          this.loading = false;
          this.$message.success(this.$t("success.notesRemoved"));
          this.$emit("ok");
        })
        .catch(() => {
          this.loading = false;
          this.$message.error(this.$t("error.supp"));
          this.$emit("cancel");
        });
    },
  },
};
