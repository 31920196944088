<template>
  <div class="card col-xl-12 col-lg-12">
    <div class="card-header card-header-flex flex-column">
      <a-tabs
        class="kit-tabs"
        :default-active-key="activeKey"
        @change="callback"
      >
        <a-tab-pane :tab="this.$t('scolarite.modules')" key="1" />
        <a-tab-pane :tab="this.$t('scolarite.modulesSpec')" key="2" />
        <a-tab-pane :tab="this.$t('scolarite.modulesVerySpec')" key="3" />
        <a-tab-pane :tab="this.$t('scolarite.affichageParametrage')" key="4" />
        <a-tab-pane :tab="this.$t('scolarite.certification')" key="5" />
        <a-tab-pane :tab="this.$t('scolarite.appreciation')" key="6" />
        <a-tab-pane :tab="this.$t('decisionConseil.decisionConseil')" key="7" />
        <a-tab-pane :tab="this.$t('bulletin.bulletinCoefs')" key="8" />
      </a-tabs>
    </div>
    <div class="card-body">
      <keep-alive>
        <module-generale
          v-if="['1', '2', '3'].includes(activeKey)"
          :key="'m' + loadKey"
          :type="type"
        />
        <component
          v-else
          v-bind:is="currentTabComponent"
          :key="'e' + loadKey"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import certification from "./components/certification";
import appreciation from "./components/appreciation";
import affichageParametrage from "./components/affichageParametrage";
import decisionConseil from "./components/decisionConseil";
import bulletinCoef from "./components/bulletinCoef";
import moduleGenerale from "./components/moduleGenerale";
import { mapState, mapGetters } from "vuex";

export default {
  name: "etablissement",
  components: {
    certification,
    appreciation,
    affichageParametrage,
    decisionConseil,
    bulletinCoef,
    moduleGenerale,
  },
  data() {
    return {
      activeKey: "1",
      loadKey: 0,
    };
  },
  computed: {
    type: function () {
      switch (this.activeKey) {
        case "1":
          return "MP";
        case "2":
          return "MT";
        case "3":
          return "MS";
      }
    },
    currentTabComponent: function () {
      switch (this.activeKey) {
        case "4":
          return "affichageParametrage";
        case "5":
          return "certification";
        case "6":
          return "appreciation";
        case "7":
          return "decisionConseil";
        case "8":
          return "bulletinCoef";
      }
    },
    ...mapState(["settings", "user"]),
    ...mapGetters("user", ["user"]),
    schoolDetails() {
      return (
        this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0] || {}
      );
    },
  },
  methods: {
    verifyBuildingAccess: function (item) {
      let access = this.schoolDetails.access.edManager[item];
      if (access && access == true) return true;
      else return false;
    },
    callback: function (key) {
      this.activeKey = key;
      this.loadKey += 1;
    },
  },
};
</script>

<style scoped>
::v-deep .ant-tabs-nav-wrap {
  overflow: hidden !important;
}
</style>
