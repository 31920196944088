<template>
  <div>
    <!-- peda -->
    <div>
      <div class="row">
        <div class="col-md-3 p-2">
          <h3 class="ml-2">{{ $t("bulletin.bulletinPedagogique") }}</h3>
        </div>
        <div class="col-md-9">
          <a-select
            size="large"
            :default-value="defaultLevel[schoolType]"
            @change="handleLevelChangePeda"
            class="pb-2 pt-2"
            style="width: 100%"
          >
            <a-select-option
              v-for="(value, name, index) in levelSelectData[schoolType]"
              :key="index"
              :value="name"
            >
              {{ value }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <div class="card p-4">
        <template v-if="!loadingDataPeda">
          <!--certif-->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetDataPeda.identUnique"
                @change="
                  (val) => {
                    if (!val.target.checked) targetDataPeda.identUnique = false;
                  }
                "
              >
                {{ $t("liste.identifiantUnique") }}
              </a-checkbox>
            </div>
          </div>
          <!--certif-->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetDataPeda.numberStudentInsc"
                @change="
                  (val) => {
                    if (!val.target.checked)
                      targetDataPeda.numberStudentInsc = false;
                  }
                "
              >
                ﻋﺪﺩ ﺍﻟﺘﻼﻣﻴﺬ ﺍﻟﻤﺮﺳّﻤﻴﻦ
              </a-checkbox>
            </div>
          </div>
        </template>
        <template v-else>
          <a-row>
            <a-col :span="16" :offset="11">
              <a-spin size="large" />
            </a-col>
          </a-row>
        </template>
      </div>
      <a-button
        @click="saveChangePeda"
        :disabled="loadingSavePeda || loadingDataPeda"
        :loading="loadingSavePeda"
        style="
          margin-right: 5px;
          margin-bottom: 10px;
          background: #8bc34a !important;
          border-color: #8bc34a !important;
        "
        type="primary"
      >
        {{ $t("all.save") }}
      </a-button>
    </div>
    <a-divider />
    <!-- specific -->
    <div>
      <div class="row">
        <div class="col-md-3 p-2">
          <h3 class="ml-2">{{ $t("bulletin.bulletinSpecifique") }}</h3>
        </div>
        <div class="col-md-9">
          <a-select
            size="large"
            :default-value="defaultLevel[schoolType]"
            @change="handleLevelChange"
            class="pb-2 pt-2"
            style="width: 100%"
          >
            <a-select-option
              v-for="(value, name, index) in levelSelectData[schoolType]"
              :key="index"
              :value="name"
            >
              {{ value }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <div class="card p-4">
        <template v-if="!loadingData">
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData.specific"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData.personalized = false;
                  }
                "
              >
                {{ $t("bulletin.moyenneSpecifique") }}
              </a-checkbox>
            </div>
            <div class="col-3" v-if="targetData.personalized">
              <a-label>{{ $t("scolarite.coefficient") }} : </a-label>
              <a-input-number
                v-model="targetData.specificCoef"
                :disabled="!targetData.specific"
              />
            </div>
            <div class="col-5">
              <a-label>{{ $t("bulletin.label") }} : </a-label>
              <a-input
                :maxLength="20"
                style="width: 85%"
                v-model="targetData.specificName"
                :disabled="!targetData.specific"
              />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData.pedagogic"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData.personalized = false;
                  }
                "
              >
                {{ $t("bulletin.moyennePedagogique") }}
              </a-checkbox>
            </div>
            <div class="col-3" v-if="targetData.personalized">
              <a-label>{{ $t("scolarite.coefficient") }} : </a-label>
              <a-input-number
                v-model="targetData.pedagogicCoef"
                :disabled="!targetData.pedagogic"
              />
            </div>
            <div class="col-5">
              <a-label>{{ $t("bulletin.label") }} : </a-label>
              <a-input
                :maxLength="20"
                style="width: 85%"
                v-model="targetData.pedagogicName"
                :disabled="!targetData.pedagogic"
              ></a-input>
            </div>
          </div>
          <!--affichage moy module-->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData.moyModule"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData.moyModule = false;
                  }
                "
              >
                Moyenne module
              </a-checkbox>
            </div>
          </div>
          <!-- begin fuseSubSubject -->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData.fuseSubSubject"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData.fuseSubSubject = false;
                  }
                "
              >
                {{ $t("bulletin.fuseSubSubject") }}
              </a-checkbox>
            </div>
          </div>
          <!-- end fuseSubSubject -->
          <!-- begin hideSubject -->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData.hideSubject"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData.hideSubject = false;
                  }
                "
              >
                {{ $t("bulletin.hideSubject") }}
              </a-checkbox>
            </div>
          </div>
          <!-- end hideSubject -->
          <!-- begin showSubModule
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData.showSubModule"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData.showSubModule = false;
                  }
                "
              >
                {{ $t("bulletin.showSubModule") }}
              </a-checkbox>
            </div>
          </div>
           end showSubModule -->
          <!-- begin hideDate -->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData.hideDate"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData.hideDate = false;
                  }
                "
              >
                {{ $t("bulletin.hideDate") }}
              </a-checkbox>
            </div>
          </div>
          <!-- end hideDate -->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData.personalized"
                :disabled="!targetData.specific || !targetData.pedagogic"
              >
                {{ $t("bulletin.moyennePersonalized") }}
              </a-checkbox>
            </div>
            <div class="col-4">
              <a-label>{{ $t("bulletin.label") }} : </a-label>
              <a-input
                :maxLength="30"
                style="width: 85%"
                v-model="targetData.personalizedName"
                :disabled="
                  !targetData.personalized ||
                  !targetData.specific ||
                  !targetData.pedagogic
                "
              />
            </div>
            <div class="col-7 mt-2" v-if="targetData.personalized">
              <a-label>{{ $t("bulletin.formulePersonalized") }} : </a-label>
              <a-tag style="font-size: 15px" color="blue">
                ( {{ $t("bulletin.moyenneSpecifique") }} *
                {{ targetData.specificCoef }} +
                {{ $t("bulletin.moyennePedagogique") }} *
                {{ targetData.pedagogicCoef }} ) /
                {{ targetData.specificCoef + targetData.pedagogicCoef }}
              </a-tag>
            </div>
          </div>
        </template>
        <template v-else>
          <a-row>
            <a-col :span="16" :offset="11">
              <a-spin size="large" />
            </a-col>
          </a-row>
        </template>
      </div>
      <a-button
        @click="saveChange"
        :disabled="loadingSave || loadingData"
        :loading="loadingSave"
        style="
          margin-right: 5px;
          margin-bottom: 10px;
          background: #8bc34a !important;
          border-color: #8bc34a !important;
        "
        type="primary"
      >
        {{ $t("all.save") }}
      </a-button>
    </div>
    <a-divider />
    <!-- very specific -->
    <div>
      <div class="row">
        <div class="col-md-3 p-2">
          <h3 class="ml-2">{{ $t("bulletin.bulletinVerySpecifique") }}</h3>
        </div>
        <div class="col-md-9">
          <a-select
            size="large"
            :default-value="defaultLevel[schoolType]"
            @change="handleLevelChangeVerySpecific"
            class="pb-2 pt-2"
            style="width: 100%"
          >
            <a-select-option
              v-for="(value, name, index) in levelSelectData[schoolType]"
              :key="index"
              :value="name"
            >
              {{ value }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <div class="card p-4">
        <template v-if="!loadingData2">
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.logo"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData2.logo = false;
                  }
                "
              >
                Logo de l'école
              </a-checkbox>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.verySpecific"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData2.personalized = false;
                  }
                "
              >
                {{ $t("bulletin.moyenneVerySpecifique") }}
              </a-checkbox>
            </div>
            <div
              class="col-3"
              v-if="
                targetData2.personalized &&
                schoolDetails.dbName !== 'everestAcademy'
              "
            >
              <a-label>{{ $t("scolarite.coefficient") }} : </a-label>
              <a-input-number
                v-model="targetData2.verySpecificCoef"
                :disabled="!targetData2.verySpecific"
              />
            </div>
            <div class="col-5">
              <a-label>{{ $t("bulletin.label") }} : </a-label>
              <a-input
                :maxLength="20"
                style="width: 85%"
                v-model="targetData2.verySpecificName"
                :disabled="!targetData2.verySpecific"
              />
            </div>
          </div>

          <!--Moyenne péda-->

          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.pedagogic"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData2.personalized = false;
                  }
                "
              >
                {{ $t("bulletin.moyennePedagogique") }}
              </a-checkbox>
            </div>
            <div
              class="col-3"
              v-if="
                targetData2.personalized &&
                schoolDetails.dbName !== 'everestAcademy'
              "
            >
              <a-label>{{ $t("scolarite.coefficient") }} : </a-label>
              <a-input-number
                v-model="targetData2.pedagogicCoef"
                :disabled="!targetData2.pedagogic"
              />
            </div>
            <div class="col-5">
              <a-label>{{ $t("bulletin.label") }} : </a-label>
              <a-input
                :maxLength="20"
                style="width: 85%"
                v-model="targetData2.pedagogicName"
                :disabled="!targetData2.pedagogic"
              ></a-input>
            </div>
          </div>
          <!--certif-->
          <div class="row mb-2">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.certif"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData2.certif = false;
                  }
                "
              >
                {{ $t("scolarite.certification") }}
              </a-checkbox>
            </div>
          </div>
          <!--ident unique-->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.identUnique"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData2.identUnique = false;
                  }
                "
              >
                {{ $t("liste.identifiantUnique") }}
              </a-checkbox>
            </div>
          </div>
          <!--nb student inscri-->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.numberStudentInsc"
                @change="
                  (val) => {
                    if (!val.target.checked)
                      targetData2.numberStudentInsc = false;
                  }
                "
              >
                ﻋﺪﺩ ﺍﻟﺘﻼﻣﻴﺬ ﺍﻟﻤﺮﺳّﻤﻴﻦ
              </a-checkbox>
            </div>
          </div>
          <!-- begin fuseSubSubject -->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.fuseSubSubject"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData2.fuseSubSubject = false;
                  }
                "
              >
                {{ $t("bulletin.fuseSubSubject") }}
              </a-checkbox>
            </div>
          </div>
          <!-- end fuseSubSubject -->
          <!-- begin hideSubject -->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.hideSubject"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData2.hideSubject = false;
                  }
                "
              >
                {{ $t("bulletin.hideSubject") }}
              </a-checkbox>
            </div>
          </div>
          <!-- end hideSubject -->
          <!-- begin showSubModule 
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.showSubModule"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData2.showSubModule = false;
                  }
                "
              >
                {{ $t("bulletin.showSubModule") }}
              </a-checkbox>
            </div>
          </div>
           end showSubModule -->
          <!-- begin hideDate -->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.hideDate"
                @change="
                  (val) => {
                    if (!val.target.checked) targetData2.hideDate = false;
                  }
                "
              >
                {{ $t("bulletin.hideDate") }}
              </a-checkbox>
            </div>
          </div>
          <!-- end hideDate -->
          <div class="row mb-1">
            <div class="col-3">
              <a-checkbox
                v-model="targetData2.personalized"
                :disabled="!targetData2.verySpecific || !targetData2.pedagogic"
              >
                {{ $t("bulletin.moyennePersonalized") }}
              </a-checkbox>
            </div>
            <div class="col-4">
              <a-label>{{ $t("bulletin.label") }} : </a-label>
              <a-input
                :maxLength="30"
                style="width: 85%"
                v-model="targetData2.personalizedName"
                :disabled="
                  !targetData2.personalized ||
                  !targetData2.verySpecific ||
                  !targetData2.pedagogic
                "
              />
            </div>
            <div class="col-12 mt-2" v-if="targetData2.personalized">
              <a-label>{{ $t("bulletin.formulePersonalized") }} : </a-label>
              <a-tag style="font-size: 15px" color="blue">
                <span dir="ltr" v-html="formattedText"></span>
              </a-tag>
            </div>
          </div>
        </template>
        <template v-else>
          <a-row>
            <a-col :span="16" :offset="11">
              <a-spin size="large" />
            </a-col>
          </a-row>
        </template>
      </div>
      <a-button
        @click="saveChangeVerySpecific"
        :disabled="loadingSave2 || loadingData2"
        :loading="loadingSave2"
        style="
          margin-right: 5px;
          margin-bottom: 10px;
          background: #8bc34a !important;
          border-color: #8bc34a !important;
        "
        type="primary"
      >
        {{ $t("all.save") }}
      </a-button>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";

export default {
  name: "classrooms",
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  computed: {
    ...mapState(["settings", "user"]),
    formattedText() {
      const moyennePedagogique = this.$t("bulletin.moyennePedagogique");

      //EDWEB-1058 : cal moyenns for everestAcademy (développement spécifique)
      if (this.schoolDetails.dbName == "everestAcademy") {
        const arabicText1 = "مجال اللغة العربية";
        const arabicText2 = "مجال العلوم و التكنولوجيا";
        const specificName = this.targetData2.verySpecificName;
        return `(${arabicText1} (${moyennePedagogique})* 2 + ${arabicText2} (${moyennePedagogique}) * 2 + ${specificName} * 2) / 6`;
      } else {
        const moyenneVerySpecifique = this.$t("bulletin.moyenneVerySpecifique");
        const pedagogicCoef = this.targetData2.pedagogicCoef;
        const verySpecificCoef = this.targetData2.verySpecificCoef;
        return `(${moyenneVerySpecifique} * ${verySpecificCoef} + ${moyennePedagogique} * ${pedagogicCoef}) / ${
          verySpecificCoef + pedagogicCoef
        }`;
      }
    },
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Paramètre d'affichage",
    });
    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;

    this.loadingData = true;
    this.loadingData2 = true;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    this.targetData.personalizedName += this.schoolDetails.ArabicName;
    this.defaultTargetData.personalizedName += this.schoolDetails.ArabicName;
    this.targetData2.personalizedName += this.schoolDetails.ArabicName;
    this.defaultTargetData2.personalizedName += this.schoolDetails.ArabicName;

    apiClient
      .post("/affichageBulletin/filter", {
        query: {
          level: this.defaultLevel[this.schoolType],
          type: "specific",
        },
      })
      .then((res) => {
        if (res.data[0]) {
          this.targetData = res.data[0];
        }
      })
      .catch((e) => {
        this.$message.error(this.$t("error.erreur"));
      })
      .finally(() => {
        this.loadingData = false;
      });

    apiClient
      .post("/affichageBulletin/filter", {
        query: {
          level: this.defaultLevel[this.schoolType],
          type: "verySpecific",
        },
      })
      .then((res) => {
        if (res.data[0]) {
          this.targetData2 = res.data[0];
        }
      })
      .catch((e) => {
        this.$message.error(this.$t("error.erreur"));
      })
      .finally(() => {
        this.loadingData2 = false;
      });
    apiClient
      .post("/affichageBulletin/filter", {
        query: {
          level: this.defaultLevel[this.schoolType],
          type: "peda",
        },
      })
      .then((res) => {
        if (res.data[0]) {
          this.targetDataPeda = res.data[0];
        }
      })
      .catch((e) => {
        this.$message.error(this.$t("error.erreur"));
      })
      .finally(() => {
        this.loadingDataPeda = false;
      });
  },
  data() {
    return {
      schoolType: null,
      schoolDetails: {},
      loadingSave: false,
      loadingSave2: false,
      loadingSavePeda: false,
      form: this.$form.createForm(this),
      data: null,
      loadingData: false,
      loadingData2: false,
      loadingDataPeda: false,
      targetKeys: [],
      selectedLevel: "1",
      selectedLevel2: "1",
      selectedLevelPeda: "1",
      defaultTargetData: {
        specific: true,
        fuseSubSubject: false,
        hideSubject: false,
        showSubModule: true,
        hideDate: false,
        pedagogic: false,
        personalized: false,
        specificCoef: 1,
        pedagogicCoef: 1,
        specificName: "معدل",
        pedagogicName: "معدل الثلاثي",
        personalizedName: "معدل الخاص بمدرسة ",
        moyModule: true,
        type: "specific",
      },
      targetData: {
        specific: true,
        fuseSubSubject: false,
        hideSubject: false,
        showSubModule: true,
        hideDate: false,
        pedagogic: false,
        personalized: false,
        specificCoef: 1,
        pedagogicCoef: 1,
        specificName: "معدل",
        pedagogicName: "معدل الثلاثي",
        personalizedName: "معدل الخاص بمدرسة ",
        moyModule: true,
        type: "specific",
      },
      targetDataPeda: {
        numberStudentInsc: false,
        identUnique: false,
        type: "peda",
      },
      defaultTargetData2: {
        verySpecific: true,
        fuseSubSubject: false,
        hideSubject: false,
        showSubModule: true,
        hideDate: false,
        pedagogic: false,
        personalized: false,
        verySpecificCoef: 1,
        pedagogicCoef: 1,
        verySpecificName: "معدل",
        pedagogicName: "معدل الثلاثي",
        personalizedName: "معدل الخاص بمدرسة ",
        numberStudentInsc: false,
        identUnique: false,
        logo: true,
        type: "verySpecific",
      },
      targetData2: {
        verySpecific: true,
        fuseSubSubject: false,
        hideSubject: false,
        showSubModule: true,
        hideDate: false,
        pedagogic: false,
        personalized: false,
        verySpecificCoef: 1,
        certif: false,
        pedagogicCoef: 1,
        verySpecificName: "معدل",
        pedagogicName: "معدل الثلاثي",
        personalizedName: "معدل الخاص بمدرسة ",
        numberStudentInsc: false,
        identUnique: false,
        logo: true,
        type: "verySpecific",
      },
      defaultLevel: {
        ecole: "1",
        college: "7",
        lycee: "10",
        collegeEtLycee: "7",
        primaireEtCollege: "1",
      },
      levelSelectData: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },
    };
  },
  methods: {
    saveChange() {
      this.loadingSave = true;
      if (this.targetData._id) {
        apiClient
          .patch("/affichageBulletin/" + this.targetData._id, {
            data: {
              specific: this.targetData.specific,
              pedagogic: this.targetData.pedagogic,
              fuseSubSubject: this.targetData.fuseSubSubject,
              hideSubject: this.targetData.hideSubject,
              showSubModule: this.targetData.showSubModule,
              hideDate: this.targetData.hideDate,
              personalized: this.targetData.personalized,
              specificCoef: this.targetData.specificCoef,
              pedagogicCoef: this.targetData.pedagogicCoef,
              specificName: this.targetData.specificName,
              pedagogicName: this.targetData.pedagogicName,
              personalizedName: this.targetData.personalizedName,
              moyModule: this.targetData.moyModule,
            },
          })
          .then((res) => {
            this.$message.success(this.$t("bulletin.parametrageSaved"));
          })
          .catch((e) => {
            this.$message.error(this.$t("error.erreur"));
          })
          .finally(() => {
            this.loadingSave = false;
          });
      } else {
        apiClient
          .put("/affichageBulletin/", {
            data: { level: this.selectedLevel, ...this.targetData },
          })
          .then((res) => {
            if (res.data) {
              this.targetData = res.data;
            }
            this.$message.success(this.$t("bulletin.parametrageSaved"));
          })
          .catch((e) => {
            this.$message.error(this.$t("error.erreur"));
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    saveChangeVerySpecific() {
      this.loadingSave2 = true;
      if (this.targetData2._id) {
        apiClient
          .patch("/affichageBulletin/" + this.targetData2._id, {
            data: {
              verySpecific: this.targetData2.verySpecific,
              pedagogic: this.targetData2.pedagogic,
              fuseSubSubject: this.targetData2.fuseSubSubject,
              hideSubject: this.targetData2.hideSubject,
              showSubModule: this.targetData2.showSubModule,
              hideDate: this.targetData2.hideDate,
              personalized: this.targetData2.personalized,
              verySpecificCoef: this.targetData2.verySpecificCoef,
              pedagogicCoef: this.targetData2.pedagogicCoef,
              verySpecificName: this.targetData2.verySpecificName,
              pedagogicName: this.targetData2.pedagogicName,
              personalizedName: this.targetData2.personalizedName,
              certif: this.targetData2.certif,
              numberStudentInsc: this.targetData2.numberStudentInsc,
              identUnique: this.targetData2.identUnique,
              logo: this.targetData2.logo,
            },
          })
          .then((res) => {
            this.$message.success(this.$t("bulletin.parametrageSaved"));
          })
          .catch((e) => {
            this.$message.error(this.$t("error.erreur"));
          })
          .finally(() => {
            this.loadingSave2 = false;
          });
      } else {
        apiClient
          .put("/affichageBulletin/", {
            data: { level: this.selectedLevel2, ...this.targetData2 },
          })
          .then((res) => {
            if (res.data) {
              this.targetData2 = res.data;
            }
            this.$message.success(this.$t("bulletin.parametrageSaved"));
          })
          .catch((e) => {
            this.$message.error(this.$t("error.erreur"));
          })
          .finally(() => {
            this.loadingSave2 = false;
          });
      }
    },
    saveChangePeda() {
      this.loadingSavePeda = true;
      if (this.targetDataPeda._id) {
        apiClient
          .patch("/affichageBulletin/" + this.targetDataPeda._id, {
            data: {
              numberStudentInsc: this.targetDataPeda.numberStudentInsc,
              identUnique: this.targetDataPeda.identUnique,
            },
          })
          .then((res) => {
            this.$message.success(this.$t("bulletin.parametrageSaved"));
          })
          .catch((e) => {
            this.$message.error(this.$t("error.erreur"));
          })
          .finally(() => {
            this.loadingSavePeda = false;
          });
      } else {
        apiClient
          .put("/affichageBulletin/", {
            data: { level: this.selectedLevelPeda, ...this.targetDataPeda },
          })
          .then((res) => {
            if (res.data) {
              this.targetDataPeda = res.data;
            }
            this.$message.success(this.$t("bulletin.parametrageSaved"));
          })
          .catch((e) => {
            this.$message.error(this.$t("error.erreur"));
          })
          .finally(() => {
            this.loadingSavePeda = false;
          });
      }
    },
    handleLevelChange(val) {
      this.selectedLevel = val;
      this.loadingData = true;
      apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel,
            type: "specific",
          },
        })
        .then((res) => {
          if (res.data[0]) {
            this.targetData = res.data[0];
          } else {
            this.targetData = { ...this.defaultTargetData };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    handleLevelChangePeda(val) {
      this.selectedLevelPeda = val;
      this.loadingDataPeda = true;
      apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevelPeda,
            type: "peda",
          },
        })
        .then((res) => {
          if (res.data[0]) {
            this.targetDataPeda = res.data[0];
          } else {
            this.targetDataPeda = {
              numberStudentInsc: false,
              identUnique: false,
              type: "peda",
            };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => {
          this.loadingDataPeda = false;
        });
    },
    handleLevelChangeVerySpecific(val) {
      this.selectedLevel2 = val;
      this.loadingData2 = true;
      apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel2,
            type: "verySpecific",
          },
        })
        .then((res) => {
          if (res.data[0]) {
            this.targetData2 = res.data[0];
          } else {
            this.targetData2 = { ...this.defaultTargetData2 };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => {
          this.loadingData2 = false;
        });
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arabicStyle {
  direction: rtl;
  font-size: 20px;
}

.card >>> .ant-table-tbody tr {
  cursor: pointer !important;
}
</style>
>
